import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Tabs, Icon } from "@virtualcapital/utogi-ui-library";

import Header from "components/header";
import MixAndMatch from "components/mixAndMatch";

import "./home.scss";

const home = () => {
  const apps = [
    { icon: "flow", title: "Flow" },
    {
      icon: "buyer-drive",
      title: "Buyer Zone",
    },
    {
      icon: "open-homes",
      title: "Open Homes",
    },
    {
      icon: "marketplace",
      title: "Marketplace",
    },
    { icon: "shop", title: "Shop" },
    {
      icon: "marketing",
      title: "Marketing",
    },
    { icon: "reports", title: "Reports" },
    {
      icon: "my-property",
      title: "My Property",
    },
    { icon: "website", title: "Website" },
    {
      icon: "target-audience",
      title: "Targeted Audience",
    },
    {
      icon: "compliance",
      title: "Compliance",
    },
    { icon: "reviews", title: "Review" },
    {
      icon: "accounts",
      title: "Accounts",
    },
    {
      icon: "file-library",
      title: "File Library",
    },
    {
      icon: "coaching",
      title: "Coaching",
    },
    {
      icon: "digital-forms",
      title: "Digital Forms",
    },
    {
      icon: "trust-account",
      title: "Trust Account",
    },
    {
      icon: "reminders",
      title: "Reminders",
    },
    {
      icon: "automation",
      title: "Automation",
    },
    {
      icon: "client-portal",
      title: "Client Portal",
    },
    {
      icon: "apps-and-api",
      title: "APPS & API",
    },
    { icon: "offers", title: "Offers" },
    {
      icon: "appraisals",
      title: "Appraisals",
    },
    { icon: "smarts", title: "Smarts" },
    { icon: "support", title: "Support" },
  ];

  return (
    <div className="home-page">
      <header>
        <Header />
        <div className="container slider-content">
          <div className="slider-left">
            <span className="heading">
              A connected real estate
              <br />
              industry platform
            </span>
            <span className="sub-heading">Utogi helps...</span>
            <div className="feature-list">
              <div>
                <p>Customers connect with Service Providers</p>
                <p>Agents connect with their Customers</p>
                <p>Agencies connect with their Agent</p>
                <p>Service Providers connect with Agents</p>
              </div>
            </div>
            <Link to="/contact-us" className="cta">
              Learn more
            </Link>
          </div>
          <div className="slider-right">
            <div className="animated-icon">
              <Icon icon="envelop" />
            </div>
            <div className="animated-icon">
              <Icon icon="people" />
            </div>
            <div className="animated-icon">
              <Icon icon="marketplace" />
            </div>
            <div className="animated-icon">
              <Icon icon="marketing" />
            </div>
            <div className="animated-icon">
              <Icon icon="compliance" />
            </div>
            <img
              src={require("assets/images/home/slider-right.png")}
              alt="slider right"
            />
          </div>
        </div>
      </header>
      <section className="how-we-can-help-section">
        <div className="container">
          <span className="heading">
            Utogi is serving up the most integrated real estate system yet
          </span>
          <span className="sub-heading">
            Intuitive | Insightful | Integrated | Intelligent
          </span>
          <div className="services-list">
            <div className="services-list-item">
              <div>
                <img
                  src={require("assets/images/home/i-am-an-agent.svg")}
                  alt="I Am An Agent"
                />
                <p className="services-list-item-heading">
                  I am a real estate agent / company
                </p>
                <p>
                  Whether you are an independent contractor, a small office, a
                  mult-office or an international agency, we have the all-in-one
                  real estate &amp; client management solution for you.
                </p>
              </div>
              <div className="button-container">
                <Link to="/contact-us">Register Interest</Link>
              </div>
            </div>
            <div className="services-list-item">
              <img
                src={require("assets/images/home/i-provide-service.svg")}
                alt="I provide service"
              />
              <div>
                <p className="services-list-item-heading">
                  I provide another service
                </p>
                <p>
                  If you provide other services inside or outside the real
                  estate industry we can help you reach more customers than ever
                  before.
                </p>
              </div>
              <div className="button-container">
                <Link to="/contact-us">Register Interest</Link>
              </div>
            </div>
            <div className="services-list-item">
              <div>
                <img
                  src={require("assets/images/home/i-have-a-property.svg")}
                  alt="I Have A Property"
                />
                <p className="services-list-item-heading">
                  I live in a property
                </p>
                <p>
                  If you live in a house and want to manage all your services in
                  one place, Utogi has the answer. Book services, connect with
                  your local real estate agent with real time updates, and
                  control access to your data all in one place.
                </p>
              </div>
              <div className="button-container">
                <Link to="/contact-us">Register Interest</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="background-list">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </section>
      <section className="why-utogi-section background-image container">
        <div className="why-utogi-section-intro">
          <span>Slice &amp; dice it your way.</span>
          <p>
            Utogi is developing a modular service where you can select the tools
            you want, when you want, to suit your business.
          </p>
          <Link to="/contact-us">Learn more</Link>
        </div>
        <div className="utogi-feature">
          <Tabs
            items={[
              {
                title: "Available now",
                content: (
                  <Fragment>
                    <div className="utogi-feature-list">
                      <div className="utogi-feature-list-item">
                        <span className="heading">
                          <img
                            src={require("assets/images/home/apps/contacts.svg")}
                            alt="Contacts"
                          />
                          Contacts
                        </span>
                        <span className="intro">
                          Manage your contacts in one location
                        </span>
                        <p>
                          Only add your contacts one time and manage team/user
                          access at a company, office, group &amp; individual
                          level.
                        </p>
                      </div>
                      <div className="utogi-feature-list-item">
                        <span className="heading">
                          <img
                            src={require("assets/images/home/apps/marketing.svg")}
                            alt="Marketing"
                          />
                          Marketing
                        </span>
                        <span className="intro">
                          Layer your marketing messages from one platform
                        </span>

                        <p>
                          Run advertising campaigns for your properties and
                          sales team on all leading real estate portals, social
                          media channels, email and print media all in one.
                        </p>
                      </div>
                      <div className="utogi-feature-list-item">
                        <span className="heading">
                          <img
                            src={require("assets/images/home/apps/maps.svg")}
                            alt="Maps"
                          />
                          Maps
                        </span>
                        <span className="intro">
                          Grow your business the smart way
                        </span>
                        <p>
                          Create a fully integrated real estate sales pipe for
                          your entire team, including full user role and task
                          customization.
                        </p>
                      </div>
                      <div className="utogi-feature-list-item">
                        <span className="heading">
                          <img
                            src={require("assets/images/home/apps/email.svg")}
                            alt="Email"
                          />
                          Email
                        </span>
                        <span className="intro">
                          Track your individual and marketing emails
                        </span>
                        <p>
                          Connect your existing email account with Utogi and
                          start sending, receiving &amp; tracking emails
                          directly from your CRM.
                        </p>
                      </div>
                      <div className="utogi-feature-list-item">
                        <span className="heading">
                          <img
                            src={require("assets/images/home/apps/target-audience.svg")}
                            alt="Target Audience"
                          />
                          Target Audience
                        </span>
                        <span className="intro">
                          Use target audiences for email lists, social media
                          campaigns, letters &amp; more.
                        </span>
                        <p>
                          Create audiences based on custom tags, demographics,
                          location, interests and even their interactions with
                          you and your properties for a more targeted approach.
                        </p>
                      </div>
                      <div className="utogi-feature-list-item">
                        <span className="heading">
                          <img
                            src={require("assets/images/home/apps/coaching.svg")}
                            alt="Coaching"
                          />
                          Coaching
                        </span>
                        <span className="intro">
                          Connect with your industry's top coaches and grow your
                          business.
                        </span>
                        <p>
                          Or as a coach, create interactive coaching material to
                          help your team build their business. This includes
                          call scripts, email templates, video training material
                          and more. Record it all to meet industry requirements.
                        </p>
                      </div>
                    </div>
                  </Fragment>
                ),
              },
              {
                title: "Coming Soon",
                content: (
                  <Fragment>
                    <div className="utogi-feature-list">
                      {apps.map(({ icon, title }) => (
                        <div key={icon} className="utogi-feature-list-item">
                          <span className="heading">
                            <img
                              src={require(`../../assets/images/home/apps/${icon}.svg`)}
                              alt="Target Audience"
                            />
                            {title}
                          </span>
                        </div>
                      ))}
                    </div>
                  </Fragment>
                ),
              },
            ]}
          />
        </div>
      </section>
      <MixAndMatch />
      <section className="all-products-section container">
        <div>
          <img
            src={require("assets/images/home/all-products.png")}
            alt="All Products"
          />
        </div>
        <div>
          <p className="heading">Still need more convincing?</p>
          <p>
            Schedule a demo with one of our friendly experts and learn how Utogi
            can help you grow your Real Estate business.
          </p>
          <Link to="/contact-us" className="our-products">
            Book now
          </Link>
        </div>
      </section>
    </div>
  );
};

export default home;
